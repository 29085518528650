import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Data from "../data/data.yaml"

import { faChevronCircleUp, faEnvelope, faClock, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faChevronCircleUp, faEnvelope, faClock, faMapMarkerAlt, faPhoneAlt)


// console.log(YAMLData)

const IndexPage = () => (
  <Layout md={Data}>
    <SEO title={Data.title} />
<h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
