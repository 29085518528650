/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import ScrollToTop from 'react-scroll-up'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Img from "gatsby-image"
import {graphql, useStaticQuery} from "gatsby"


import Footer from "./footer"
import Header from "./header"
import Services from "./services"
import Slider from "./slider"


import "./layout.scss"
import "./theme.scss"


import Data from "../data/data.yaml"


const highlights = Data.highlights.map((item, index) => <div key={index}>
  <h1 key={'h1' + index}>{item.title}</h1>
  <p key={'p' + index}>{item.text}</p>
</div>)


const details = Data.details.map((item, index) => <div key={index} class="detail-container">
  <div class="icon"><FontAwesomeIcon icon={item.icon} /></div>
  <div class="detail">
    <h1 key={'h1' + index}>{item.title}</h1>
    <p key={'p' + index}>{item.text}</p>
  </div>
</div>)

const services = Data.cuidados.list.map((item, index) => {
  return (<details key={'detail' + index}>
    <summary>{item.title}</summary><p>{item.text}</p>
  </details>)
})


const Layout = ({ children, md }) => {
  const images = useStaticQuery(graphql`
    query Imagess {
      about: file(relativePath: {eq: "about.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={md.title} menu={md.menu} />
      <Slider id="slider" autoplay delayLength={5000} />
      <div>
        <section id="details">{details}</section>
        <section id="quem-somos">
          <Img fluid={images.about.childImageSharp.fluid}/>
          <div>
            <h1>{Data.intro.title}</h1>
            <p>{Data.intro.headline}</p>
          </div>
        </section>
        <section id="highlights">{highlights}</section>
        <section id="servicos">
          <div id="title">
            <h1>Serviços</h1>
            <p>A nossa farmácia é um agente da saúde, dispondo de serviços que previnem e asseguram o seu bem-estar, cuidando da sua saúde.</p>
          </div>
          <Services />
          <div id="cuidados">
            <div id="info">
              <h1>{Data.cuidados.title}</h1>
              <p>{Data.cuidados.description}</p>
            </div>
            <div id="accordion">{services}</div>
          </div>
        </section>
        {/* <div id="contactos">
          <div class="title">
            <h1>Contactos</h1>
            <p>{Data.contactos.description}</p> 
          </div>
        </div> */}
        <Footer />
        <ScrollToTop showUnder={160}>
          <FontAwesomeIcon icon="chevron-circle-up" />
        </ScrollToTop>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
