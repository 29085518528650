
import React from "react"
import Img from "gatsby-image"
import {graphql, useStaticQuery} from "gatsby"

import Data from "../data/data.yaml"

const Services = () => {
    const image = useStaticQuery(graphql`
        query ImageQuery {
            allImageSharp {
                nodes {
                    sizes {
                        originalName
                    }
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const images = image.allImageSharp.nodes
    .filter(node => Data.services.services.includes(node.sizes.originalName))
    .map(image => <Img fluid={image.fluid} />)

    return <div id="images">{images}</div>
}

export default Services