import React, { useState, useEffect, useCallback } from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import EmblaCarouselReact from 'embla-carousel-react'
import ReactDOM from 'react-dom';


import Data from "../data/data.yaml";


import containerStyles from "./slider.module.scss";
import useInterval from "./useInterval";

const Slider = ({ autoplay, delayLength, children }) => {
    const [embla, setEmbla] = useState(null);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const [delay, setDelay] = useState(delayLength);
    const [isRunning, setIsRunning] = useState(autoplay);
  
    const scrollTo = useCallback(index => embla.scrollTo(index), [embla]);
    const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla.scrollNext(), [embla]);

    useInterval(() => {
      if (selectedIndex === scrollSnaps.length - 1) {
        scrollTo(0);
      } else {
        scrollNext();
      }
    }, isRunning ? delay : null);

    useEffect(() => {
      const onSelect = () => {
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
      };
      if (embla) {
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
        onSelect();
      }
      return () => embla && embla.destroy();
    }, [embla]);
    
    function handleIsRunningChange(e) {
      setIsRunning(e.target.checked);
    }
    
    function handleDelayChange(e) {
      setDelay(Number(e.target.value));
    }

    const sliderClasses = item => {
      return item.inverted ? (containerStyles.embla__slide__inner + ' ' + containerStyles.inverted) : containerStyles.embla__slide__inner;
    }

    const image = useStaticQuery(graphql`
    query slides {
        slideOne: file(relativePath: { eq: "farmacia_magalhaes_02.png" }) {
            childImageSharp {
              fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slideTwo: file(relativePath: { eq: "farmacia_magalhaes_04.png" }) {
            childImageSharp {
              fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slideThree: file(relativePath: { eq: "farmacia_paranhos_06.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`);

const slides = Data.slider.map((item, index) => {
  // console.log(item)

  const containerClass = sliderClasses(item);

  return (
    
  <div key={'slide' + index} style={{ flex: '0 0 100%' }} className={containerStyles.embla__slide}>
    <Img fluid={image[item.slide].childImageSharp.fluid}/>
    <div className={containerClass}>
      <h1>{item.title}</h1><h2>{item.subtitle}</h2><p>{item.text}</p>
    </div>
  </div>)
})
    return (
        <div className={containerStyles.embla}>
            <EmblaCarouselReact emblaRef={setEmbla} className={containerStyles.embla__viewport} options={{ loop: true}}>
            <div style={{ display: 'flex' }} className={containerStyles.embla__container}>
              {slides}
            </div>
          </EmblaCarouselReact>
        </div>
    );
}
export default Slider
