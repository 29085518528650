import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useEffect} from "react"
import Img from "gatsby-image"

import { AnchorLink } from "gatsby-plugin-anchor-links";
import {graphql, useStaticQuery} from "gatsby";

import Data from "../data/data.yaml"

const Header = ({ siteTitle }) => {
  useEffect(() => {
    const header = document.getElementById("header");
    const details = document.getElementById('details');
    const sticky = details.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => window.removeEventListener("scroll", scrollCallBack);
  }, []);


  const onMouseOver = ev => {
    const index = parseInt(ev.currentTarget.id, 10);
    const underline = document.querySelectorAll(".underline")[0];
    let offset = 0;

    for (const li of document.querySelectorAll('li')) {
      if (parseInt(li.id, 10) < index) {
        offset += li.offsetWidth;
      }
    }

    underline.style.transform = 'translate3d(' + offset + 'px,0,0)';
    underline.style.width = `${ev.currentTarget.offsetWidth}px`

  }
  const menu = Data.menu.map((item, index) => {
    return (
    <li id={index} onMouseOver={onMouseOver} style={{flex: item.flex}}>
      <AnchorLink key={index} to={'/#' + item.link}><span>{item.name}</span></AnchorLink>
    </li>
    );
  })
  const images = useStaticQuery(graphql`
    query Logo {
      logo: file(relativePath: {eq: "logos/logo.png"}) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (<header id="header">
    <div
      style={{
        maxWidth: 960,
        width: '200px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <h1 style={{ margin: 0, width: '100%' }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <Img fluid={images.logo.childImageSharp.fluid}/>
        </Link>
      </h1>
    </div>
    <nav>
      <div class="underline"></div>
      <ul class="with-indicator">{menu}</ul>
    </nav>
  </header>)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
