import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Data from "../data/data.yaml"

const links = Data.rodape.links.map((item, index) => (
  <a href={item.link}>{item.name}</a>
))

const Footer = () => {
  const image = useStaticQuery(graphql`
    query Images {
      infarmed: file(relativePath: { eq: "fm_infarmed.png" }) {
        childImageSharp {
          fixed(height: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      valormed: file(relativePath: { eq: "fm_valormed_1.png" }) {
        childImageSharp {
          fixed(height: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      saude24: file(relativePath: { eq: "fm_saude24.png" }) {
        childImageSharp {
          fixed(height: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logos = Data.rodape.logos.map(item => (
    <a href={item.link}>
      <Img fixed={image[item.image].childImageSharp.fixed} />
    </a>
  ))

  return (
    <footer>
      <div id="links">{links}</div>
      <div id="direcao">
        <p>Direção Técnica</p>
        <p>{Data.rodape.direcao}</p>
      </div>
      <div>{logos}</div>
    </footer>
  )
}

export default Footer
